import { io } from "socket.io-client";
let config = require('../../config');

let socket;

export const connect = () => {
  const session = sessionStorage.getItem("user");
  const user = JSON.parse(session);
  socket = io(
    window.location.origin,
    {
      reconnectionDelayMax: 10000,
      query: {
        token: user.token,
      },
      withCredentials: true,
      transports: ["websocket", "polling"],
    }
  );

  socket.io.on("reconnect", (attempt) => { });

  return socket;
};

export const add = (type, id) => {
  socket.emit("join-room", {
    type: type,
    id: id,
  });
};

export const leaveRoom = (type, id) => {
  socket.emit("leave-room", {
    type: type,
    id: id,
  });
};

