const dotenv = require('dotenv');
var path = require('path');

dotenv.config({ path: path.resolve(__dirname, '../conf/application.secrets') });

module.exports = {
	node: {
		port: process.env.OPENSHIFT_NODEJS_PORT || 8282,
		host: process.env.OPENSHIFT_NODEJS_IP || '0.0.0.0'
	},  
	api : {
		API_MASTERDATA: process.env.API_MASTERDATA || 'http://localhost:3350',
		API_SECURITY: process.env.API_SECURITY || 'http://localhost:3360',
		API_OUTBOUND: process.env.API_OUTBOUND || 'http://localhost:3380',
		WS_COMMS: process.env.WS_COMMS || 'http://localhost:8080',
		GOOGLE_SSO_CLIENT: process.env.GOOGLE_SSO_CLIENT || ''
	},
	general: {
		phase2Enabled: process.env.PHASE_2_ENABLED || '0',
		version: process.env.VERSION || '0',
		enviroment: process.env.ENVIROMENT || ''
	},
	apm: {
		APM_TOKEN: process.env.VUE_APP_APMTOKEN,
		APM_URL: process.env.VUE_APP_APMURL,
		APM_ENV: process.env.VUE_APP_APMENV
	},

}