'use strict';
import { app } from '@/main.js'

const DeliveryService = {
    getDeliveries(user) {
        return new Promise((resolve, reject) => {
            let userPlant = {
                plant: user.plant
            }
            app.config.globalProperties.$axios.post('/delivery/searchDeliveries', userPlant)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getPendingDeliveries(user) {
        return new Promise((resolve, reject) => {
            let userPlant = {
                plant: user.plant
            }
            app.config.globalProperties.$axios.post('/delivery/searchPendingDeliveries', userPlant)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getLoadingDeliveries(user) {
        return new Promise((resolve, reject) => {
            let userPlant = {
                plant: user.plant
            }
            app.config.globalProperties.$axios.post('/delivery/searchLoadingDeliveries', userPlant)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    setDeliveryTransitStatus(user, deliveryId, status, materialIndex) {
        return new Promise((resolve, reject) => {
            let userData = {
                plant: user.plant,
                userId: user.id_user,
                fullname: user.fullname
            }
            app.config.globalProperties.$axios.post('/delivery/setDeliveryTransitStatus', { userData: userData, deliveryId: deliveryId, status: status, materialIndex: materialIndex })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    signDelivery(deliverySign, user) {
        let userToken = {
            token: user.token
        }
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/signDelivery', { deliverySign: deliverySign, user: userToken })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    getDelivery(id) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/delivery/searchDelivery/${id}`)
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    },
    blockDelivery(delivery, user) {
        let userCheck = {
            fullname: user.fullname
        }
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.post('/delivery/blockDelivery', { delivery: delivery, user: userCheck })
                .then(response => {
                    return resolve(response)
                }).catch(error => {
                    reject({
                        ret: error.response.status
                        , msg: error.response.data
                    });
                });
        })
    }
}
export default DeliveryService;